
import { Component, Vue, Getter } from 'nuxt-property-decorator'
import { AbbyPlans, PlanFeature, StripeProductFrequency, RegisteredType } from '@abby/core-legacy'
import { IAuthUser } from '~/store/auth'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'

@Component({
  components: { AbbyPlanChip },
})
export default class AbbyProAction extends Vue {
  @Getter('auth/user')
  user!: IAuthUser

  get isPromotionDay () {
    const isSubProYearly = this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO && !this.$planManager.isTrial() && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR

    const isPromotionDay = this.$dayjs().isBetween('2023-11-20', '2025-03-31', 'day', '[]')
    return !isSubProYearly && isPromotionDay
  }

  get promotionDayValue () {
    return '-25%'
  }

  get promotionDayText () {
    return 'jusqu\'au 3 octobre'
  }

  get isTrial () {
    return this.$planManager.isTrial()
  }

  get isPaidAndMonthly () {
    return [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_START].includes(this.$planManager.whichPlanCompanyHas()) && this.$planManager.whichFrequencyPlanCompanyHas() === 'month'
  }

  get isTrialExpired () {
    return this.$planManager.isTrialExpired()
  }

  openAbbyPlansModal () {
    this.$planManager.openAbbyPlansModal({
      feature: 'action' as PlanFeature,
      openPlan: true,
    })
  }

  get remainingDays () {
    const plan = this.$planManager.companyPlan()
    if (!plan) {
      return null
    }
    const days = this.$dayjs(plan.extendedTrialAt || plan.trialAt).toNow(true)
    return {
      days,
    }
  }
}

import { CurrencyCode } from '../../../enums';

export enum VatMention {
  NOT_SUBJECT = 'not_subject',
  SECOND_HAND_GOOD = 'second_hand_good',
  ART_OBJECT = 'art_object',
  COLLECTION_ANTIQUE = 'collection_antique',
  TRAVEL_AGENCY = 'travel_agency',
  VAT_NOT_APPLICABLE = 'vat_not_applicable',
  VAT_NOT_APPLICABLE_2 = 'vat_not_applicable_2',
  VAT_EXEMPTION = 'vat_exemption',
  VAT_EXEMPTION_EU_SALE = 'vat_exemption_eu_sale',
  VAT_EXEMPTION_EU_SERVICE = 'vat_exemption_eu_service',
  VAT_CONSTRUCTION = 'vat_construction',
  VAT_PSYCHOLOGIST = 'vat_psychologist',
  VAT_PSYCHOLOGIST_2 = 'vat_psychologist_2',
  VAT_REVERSE_CHARGE = 'vat_reverse_charge',
  VAT_REVERSE_CHARGE_2 = 'vat_reverse_charge_2',
  VAT_REVERSE_CHARGE_3 = 'vat_reverse_charge_3',
  VAT_REDUCED_RATE = 'vat_reduced_rate',
}

export enum PaymentDelay {
  AT_RECEPTION = 'at_reception',
  END_OF_MONTH = 'end_of_month',
  SEVEN_DAYS = 'seven_days',
  TEN_DAYS = 'ten_days',
  FIFTEEN_DAYS = 'fifteen_days',
  THIRTY_DAYS = 'thirty_days',
  THIRTY_DAYS_END_OF_MONTH = 'thirty_days_end_of_month',
  FORTY_FIVE_DAYS = 'forty_five_days',
  FORTY_FIVE_DAYS_END_OF_MONTH = 'forty_five_days_end_of_month',
  SIXTY_DAYS = 'sixty_days',
  SIXTY_DAYS_END_OF_MONTH = 'sixty_days_end_of_month',
  NINETY_DAYS = 'ninety_days',
  NINETY_DAYS_END_OF_MONTH = 'ninety_days_end_of_month',
  ONE_HUNDRED_AND_TWENTY_DAYS = 'one_hundred_and_twenty_days',
  OTHER = 'other',
}

export enum LatePenalty {
  THREE_TIME_LEGAL_RATE = 'three_time_legal_rate',
  BCE_DIRECTOR_RATE_PLUS_TEN_POINTS = 'bce_director_rate_plus_ten_points',
  OTHER = 'other',
}

export enum Template {
  MERCURY = 'mercury',
  ATLAS = 'atlas',
  TITAN = 'titan',
}

export enum Column {
  NUMBER = 'number',
  DESCRIPTION = 'description',
  UNITY = 'unity',
  QUANTITY = 'quantity',
  UNIT_PRICE_WITHOUT_TAX = 'unit_price_without_tax',
  TVA = 'tva',
  TOTAL_WITHOUT_TAX = 'total_without_tax',
  TOTAL_WITH_TAX = 'total_with_tax',
}

export interface ReadBankInformation {
  bic: string;
  iban: string;
  holder: string;
}

export type Columns = {
  order: number;
  name: Column;
}[];

export interface ReadTheme {
  id: string;
  template: Template;
  primaryColor: string;
  columns: Columns;
  background: string;
}

export interface ReadBillingConfiguration {
  id: string;
  companyId: string;
  logo: string;
  language?: string;
  currency?: string;
  headerNote?: string;
  footerNote?: string;
  generalTermsAndConditionsOfSale?: string;
  vatMention?: VatMention;
  paymentDelay?: PaymentDelay;
  latePenalty?: LatePenalty;
  lumpSumCompensation?: number;
  paymentMethods?: string[];
  currencyCode?: CurrencyCode;
  email?: string;
  phoneNumber?: string;
  theme: ReadTheme;
  bankInformation?: ReadBankInformation;
  test?: boolean;
  tiersPrestationActivated?: boolean;
  tiersPrestationCredentials?: {
    clientId: string;
    novaNumber?: string;
  }
}
